import { Link } from "gatsby"
import React from "react"

import { Col, Container, Row } from "react-bootstrap"

const ContactTalk = () => {
  return (
    <section className="talk-to-human">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            < h2 className="th-title text-white">
                Talk to a real human! Call us at:
            </h2>
            <p className="contact-number text-white"><strong>Sales: <Link to="tel:+918113886886" className="text-white">+91 8113886886</Link></strong></p>
            <p className="contact-time text-white">(Monday to Friday between 10 am and 6 pm Indian Time)</p>            
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ContactTalk
